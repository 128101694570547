<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer></v-spacer>
                <span class="text-h5">{{this.form.id ? 'Edição' : 'Cadastro'}} de Entregador</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 160px">
                <v-text-field
                    label="Nome"
                    v-model="form.nome"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                />
                <v-text-field
                    label="WhatsApp"
                    v-model="form.whatsapp"
                    prefix="+55"
                    placeholder="(99) 99999-9999"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                />
                <v-text-field
                    label="Placa"
                    v-model="form.placa"
                    v-mask="['XXXXXXX']"
                    outlined
                    dense
                    hide-details
                    class="mt-2"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'Entregador',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            nome: '',
            whatsapp: '',
            placa: '',
            ativo: true
        },
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        }
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`entregadores/${id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Não foi possivel concluir a operação', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('entregadores', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
